body {
  background-color: #060c3e;
  color: #f8f9fb;
}

body::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: #031566;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
